import { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function PujaList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formid, setFormid] = useState("");
    const updateMaster = useAppStore((state) => state.updateMaster);
    const master = useAppStore((state) => state.master);
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const navigate = useNavigate();
    const postimgURL = process.env.REACT_APP_POST_URL;

    useEffect(() => {
        if (!token) {
            navigate("/");
        }

        if (utype == "Admin") {
            getUsers();
        }
    }, []);

    const getUsers = async () => {
        let posturl = baseURL + "puja";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateMaster(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "puja/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsers();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            updateMaster([v]);
        } else {
            getUsers();
        }
    };

    const editData = (
        id,
        serial_no,
        name,
        title,
        description,
        vidhi,
        katha,
        samgri,
        aarti,
        image,
        slug,
        status,
        type,
        keywords,

    ) => {
        const data = {
            id: id,
            serial_no: serial_no,
            name: name,
            title: title,
            description: description,
            vidhi: vidhi,
            katha: katha,
            samgri: samgri,
            aarti: aarti,
            image: image,
            slug: slug,
            status: status,
            type: type,
            keywords: keywords,
        };
        setFormid(data);
        navigate("/edit-puja", {
            state: {
                id: id,
                serial_no: serial_no,
                name: name,
                title: title,
                description: description,
                vidhi: vidhi,
                katha: katha,
                samgri: samgri,
                aarti: aarti,
                image: image,
                slug: slug,
                status: status,
                type: type,
                keywords: keywords,
            },
        });
    };

    return (
        <>
            <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                <div style={{ display: "flex" }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                    >
                        Puja Vidhi
                    </Typography>
                </div>

                <Divider />
                <Box height={10} />
                <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={master}
                        sx={{ width: 300 }}
                        onChange={(e, v) => filterData(v)}
                        getOptionLabel={(master) => master.name || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Search"
                            />
                        )}
                    />

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button
                        variant="contained"
                        endIcon={<AddCircleIcon />}
                        onClick={() => {
                            navigate("/add-puja");
                        }}
                    >
                        Add
                    </Button>
                </Stack>
                <Box height={10} />
                {master.length > 0 && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Serial No
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Image
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Created At
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {master
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left">
                                                    {row.serial_no}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <img
                                                        src={`${postimgURL}${row.image}`}
                                                        height="70px"
                                                        width="70px"
                                                        style={{
                                                            borderRadius:
                                                                "15px",
                                                        }}
                                                        loading="lazy"
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(
                                                        row.created_at
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                editData(
                                                                    row.id,
                                                                    row.serial_no,
                                                                    row.name,
                                                                    row.title,
                                                                    row.description,
                                                                    row.vidhi,
                                                                    row.katha,
                                                                    row.samgri,
                                                                    row.aarti,
                                                                    row.image,
                                                                    row.slug,
                                                                    row.status,
                                                                    row.type,
                                                                    row.keywords,
                                                                );
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "darkred",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                deleteUser(
                                                                    row.id
                                                                );
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={master.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {master.length == 0 && (
                <>
                    <Paper
                        sx={{
                            width: "98%",
                            overflow: "hidden",
                            padding: "12px",
                        }}
                    >
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={30}
                        />
                        <Box height={40} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                    </Paper>
                </>
            )}
        </>
    );
}
