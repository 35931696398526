import create from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  dopen: true,
  rows: [],
  username: '',
  useremail: '',
  password: '',
  categories: [],
  users: [],
  healers: [],
  patient: [],
  token: '',
  utype: '',
  uid: '',
  questions: [],
  yourhealers: [],
  yourpatients: [],
  master: [],
  blog: [],
  updateBlog: (blog) => set((state) => ({ blog: blog })),
  updateMaster: (master) => set((state) => ({ master: master })),
  updateUserEmail: (useremail) => set((state) => ({ useremail: useremail })),
  updateYourPatients: (yourpatients) => set((state) => ({ yourpatients: yourpatients })),
  updateYourHealers: (yourhealers) => set((state) => ({ yourhealers: yourhealers })),
  updateQuestions: (questions) => set((state) => ({ questions: questions })),
  updatePatient: (patient) => set((state) => ({ patient: patient })),
  updateHealers: (healers) => set((state) => ({ healers: healers })),
  updateUid: (uid) => set((state) => ({ uid: uid })),
  updateUtype: (utype) => set((state) => ({ utype: utype })),
  updateToken: (token) => set((state) => ({ token: token })),
  updateUsers: (users) => set((state) => ({ users: users })),
  updateCategories: (categories) => set((state) => ({ categories: categories })),
  updateUsername: (username) => set((state) => ({ username: username })),
  updatePassword: (password) => set((state) => ({ password: password })),
  setRows: (rows) => set((state) => ({ rows: rows })),
  updateDopen: (dopen) => set((state) => ({ dopen: dopen })),
});

appStore = persist(appStore, { name: "cdot_store_api" });
export const useAppStore = create(appStore);
