import { Routes, Route, HashRouter } from "react-router-dom";
import Puja from "./pages/Puja";
import AddPuja from "./puja/AddPuja";
import EditPuja from "./puja/EditPuja";
import Blog from "./pages/Blog";
import AddBlog from "./blog/AddBlog";
import EditBlog from "./blog/EditBlog";
import Home from "./pages/Home";
import User from "./pages/User";
import Login from "./Login";
import { useAppStore } from "./appStore";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";

export default function App() {
    const utype = useAppStore((state) => state.utype);

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" exact element={<Login />}></Route>
                    <Route
                        path="/register"
                        exact
                        element={<Register />}
                    ></Route>
                    <Route
                        path="/reset-password"
                        exact
                        element={<ForgotPassword />}
                    ></Route>
                    {utype === "Admin" && (
                        <>
                            <Route
                                path="/home"
                                exact
                                element={<Home />}
                            ></Route>
                            <Route
                                path="/puja"
                                exact
                                element={<Puja />}
                            ></Route>
                            <Route
                                path="/add-puja"
                                exact
                                element={<AddPuja />}
                            ></Route>
                            <Route
                                path="/edit-puja"
                                exact
                                element={<EditPuja />}
                            ></Route>
                            <Route
                                path="/blog"
                                exact
                                element={<Blog />}
                            ></Route>
                            <Route
                                path="/add-blog"
                                exact
                                element={<AddBlog />}
                            ></Route>
                            <Route
                                path="/edit-blog"
                                exact
                                element={<EditBlog />}
                            ></Route>
                            <Route
                                path="/user"
                                exact
                                element={<User />}
                            ></Route>
                        </>
                    )}
                </Routes>
            </HashRouter>
        </>
    );
}
