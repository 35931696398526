import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import bg from "./bg/signin.svg";
import bgimg from "./bg/backimg.jpg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const center = {
    position: "relative",
    top: "50%",
    left: "30%",
};

export default function Register() {
    const [open, setOpen] = useState(false);
    const vertical = "top";
    const horizontal = "right";
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const updateUsername = useAppStore((state) => state.updateUsername);
    const updatePassword = useAppStore((state) => state.updatePassword);
    const updateToken = useAppStore((state) => state.updateToken);
    const updateUtype = useAppStore((state) => state.updateUtype);
    const updateUid = useAppStore((state) => state.updateUid);
    const updateUserEmail = useAppStore((state) => state.updateUserEmail);
    const baseURL = process.env.REACT_APP_API_URL;

    const handleSubmit = async (event) => {
        event.preventDefault();

        let posturl = baseURL + "register";
        if (password == confirmpassword) {
            await axios
                .post(posturl, {
                    name: name,
                    email: email,
                    password: password,
                })
                .then(function (res) {
                    console.log(res.data.user);
                    updateUserEmail(res.data.user.email);
                    updateToken(res.data.token);
                    updateUsername(name);
                    updatePassword(password);
                    updateUtype(res.data.user.type);
                    updateUid(res.data.user.id);
                    navigate("/home");
                    // Swal.fire("Submitted!", "Your file has been submitted.", "success");
                })
                .catch(function (error) {
                    Swal.fire(
                        "Failed!",
                        "Please enter correct username and password!",
                        "error"
                    );
                    console.log("kcheckpost" + error); //return 429
                });
        } else {
            Swal.fire(
                "Failed!",
                "Password and confirm password does not match!",
                "error"
            );
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    function TransitionLeft(props) {
        return <Slide {...props} direction="left" />;
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                TransitionComponent={TransitionLeft}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Failed! Enter correct username and password.
                </Alert>
            </Snackbar>
            <div
                style={{
                    backgroundImage: `url(${bgimg})`,
                    backgroundSize: "cover",
                    height: "100vh",
                    color: "#f5f5f5",
                }}
            >
                <Box sx={boxstyle}>
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundImage: `url(${bg})`,
                                    backgroundSize: "cover",
                                    marginTop: "40px",
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    height: "63vh",
                                    color: "#f5f5f5",
                                }}
                            ></Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundSize: "cover",
                                    height: "70vh",
                                    minHeight: "500px",
                                    backgroundColor: "#3b33d5",
                                }}
                            >
                                <ThemeProvider theme={darkTheme}>
                                    <Container>
                                        <Box height={25} />
                                        <Box sx={center}>
                                            <Avatar
                                                sx={{
                                                    ml: "85px",
                                                    mb: "4px",
                                                    bgcolor: "#ffffff",
                                                }}
                                            >
                                                {/* <LockOutlinedIcon /> */}
                                            </Avatar>
                                            <Typography
                                                component="h1"
                                                variant="h4"
                                            >
                                                Create Account
                                            </Typography>
                                        </Box>
                                        <Box
                                            component="form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                            sx={{ mt: 2 }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: "3em",
                                                        mr: "3em",
                                                    }}
                                                >
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="name"
                                                        label="Name"
                                                        name="name"
                                                        value={name}
                                                        onChange={
                                                            handleNameChange
                                                        }
                                                        autoComplete="email"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: "3em",
                                                        mr: "3em",
                                                    }}
                                                >
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Email"
                                                        name="email"
                                                        value={email}
                                                        onChange={
                                                            handleEmailChange
                                                        }
                                                        autoComplete="email"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: "3em",
                                                        mr: "3em",
                                                    }}
                                                >
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        id="password"
                                                        autoComplete="new-password"
                                                        value={password}
                                                        onChange={
                                                            handlePasswordChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: "3em",
                                                        mr: "3em",
                                                    }}
                                                >
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        name="confirmpassword"
                                                        label="Confirm Password"
                                                        type="password"
                                                        id="confirmpassword"
                                                        autoComplete="new-password"
                                                        value={confirmpassword}
                                                        onChange={
                                                            handleConfirmPasswordChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: "5em",
                                                        mr: "5em",
                                                    }}
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        fullWidth="true"
                                                        size="large"
                                                        sx={{
                                                            mt: "15px",
                                                            mr: "20px",
                                                            borderRadius: 28,
                                                            color: "#ffffff",
                                                            minWidth: "170px",
                                                            backgroundColor:
                                                                "#FF9A01",
                                                        }}
                                                    >
                                                        Register
                                                    </Button>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: "3em",
                                                        mr: "3em",
                                                    }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Already have an
                                                            Account?{" "}
                                                            <span
                                                                style={{
                                                                    color: "#beb4fb",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    navigate(
                                                                        "/"
                                                                    );
                                                                }}
                                                            >
                                                                Sign In
                                                            </span>
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
