import React, { useState, useRef, useMemo, useCallback } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function AddPuja() {
    const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const token = useAppStore((state) => state.token);
    const [serial_no, setSerialNo] = useState(null);
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [vidhi, setVidhi] = useState("");
    const [katha, setKatha] = useState("");
    const [samgri, setSamgri] = useState("");
    const [aarti, setAarti] = useState("");
    const [status, setStatus] = useState("Published");
    const [type, setType] = useState("Post");
    const [keywords, setKeywords] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const img = useRef("");
    const vidhiconfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Puja Vidhi",
        }),
        []
    );
    const kathaconfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Katha",
        }),
        []
    );
    const samgriconfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Samgri",
        }),
        []
    );
    const aarticonfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Aarti",
        }),
        []
    );

    const createUser = async (url) => {
        let posturl = baseURL + "puja";
        const tid = toast.loading("Please wait...");

        await axios
            .post(
                posturl,
                {
                    serial_no: serial_no,
                    name: name,
                    title: title,
                    description: description,
                    image: image,
                    vidhi: vidhi,
                    katha: katha,
                    samgri: samgri,
                    aarti: aarti,
                    keywords: keywords,
                    status: status,
                    type: type,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                toast.update(tid, {
                    render: "Created!",
                    type: "success",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                });
                navigate("/puja");
            })
            .catch(function (error) {
                toast.update(tid, {
                    render: error,
                    type: "error",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                    theme: "light",
                });
                console.log("kcheckpost" + error); //return 429
            });
    };

    const handleSerialChange = (event) => {
        setSerialNo(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const onVidhiBlur = useCallback(
        (newContent) => {
            setVidhi(newContent);
        },
        [vidhi]
    );

    const onKathaBlur = useCallback(
        (newContent) => {
            setKatha(newContent);
        },
        [katha]
    );

    const onSamgriBlur = useCallback(
        (newContent) => {
            setSamgri(newContent);
        },
        [samgri]
    );

    const onAartiBlur = useCallback(
        (newContent) => {
            setAarti(newContent);
        },
        [aarti]
    );

    const handlePicChange = (event) => {
        console.log(event.target.files);
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setImage(e.target.result);
        };
    };

    const handleKeywordChange = (event) => {
        setKeywords(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Add Puja Vidhi
                            </Typography>
                            <Box height={10} />
                            <IconButton
                                style={{
                                    position: "absolute",
                                    top: "-10",
                                    left: "20",
                                }}
                                onClick={() => {
                                    navigate("/puja");
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box height={20} />
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <TextField
                                        value={serial_no}
                                        onChange={handleSerialChange}
                                        label="SL No"
                                        type="number"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                            marginTop: "30px",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        value={name}
                                        onChange={handleNameChange}
                                        label="Name"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                            marginTop: "30px",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={title}
                                        onChange={handleTitleChange}
                                        label="Title"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                            marginTop: "30px",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ minWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="branch-label">
                                            Status*
                                        </InputLabel>
                                        <Select
                                            labelId="branch-label"
                                            size="small"
                                            value={status}
                                            label="Status*"
                                            onChange={handleStatusChange}
                                        >
                                            <MenuItem value="Published">
                                                Published
                                            </MenuItem>
                                            <MenuItem value="Drafted">
                                                Drafted
                                            </MenuItem>
                                            <MenuItem value="Trashed">
                                                Trashed
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ minWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="branch-label">
                                            Type*
                                        </InputLabel>
                                        <Select
                                            labelId="branch-label"
                                            size="small"
                                            value={type}
                                            label="Type*"
                                            onChange={handleTypeChange}
                                        >
                                            <MenuItem value="Post">
                                                Post
                                            </MenuItem>
                                            <MenuItem value="Page">
                                                Page
                                            </MenuItem>
                                            <MenuItem value="Blog">
                                                Blog
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        label="Description"
                                        size="small"
                                        sx={{
                                            marginTop: "0px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={false}
                                        id="keywords"
                                        name="keywords"
                                        value={keywords}
                                        onChange={handleKeywordChange}
                                        label="Keywords"
                                        size="small"
                                        sx={{
                                            marginTop: "0px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label htmlFor="">Puja Vidhi</label>
                                    <JoditEditor
                                        value={vidhi}
                                        placeholder="Vidhi"
                                        config={vidhiconfig}
                                        tabIndex={1}
                                        onBlur={onVidhiBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label htmlFor="">Puja Katha</label>
                                    <JoditEditor
                                        value={katha}
                                        placeholder="Katha"
                                        config={kathaconfig}
                                        tabIndex={1}
                                        onBlur={onKathaBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label htmlFor="">Puja Samgri</label>
                                    <JoditEditor
                                        value={samgri}
                                        placeholder="Samgri"
                                        config={samgriconfig}
                                        tabIndex={1}
                                        onBlur={onSamgriBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label htmlFor="">Puja Aarti</label>
                                    <JoditEditor
                                        value={aarti}
                                        placeholder="Aarti"
                                        config={aarticonfig}
                                        tabIndex={1}
                                        onBlur={onAartiBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        onChange={handlePicChange}
                                        accept="/image/*"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">
                                        <Button
                                            variant="contained"
                                            onClick={createUser}
                                        >
                                            Submit
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
