import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function EditForm({ fid, closeEvent }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState("User");
    const baseURL = process.env.REACT_APP_API_URL;
    const users = useAppStore((state) => state.users);
    const updateUsers = useAppStore((state) => state.updateUsers);
    const token = useAppStore((state) => state.token);

    useEffect(() => {
        console.log("FID: ", fid);
        setName(fid.name);
        setEmail(fid.email);
        setPassword(fid.password);
        setType(fid.type);
    }, []);

    const createUser = async (url) => {
        let posturl = baseURL + "users/" + fid.id;

        await axios
            .put(
                posturl,
                {
                    name: name,
                    email: email,
                    password: password,
                    type: type,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                getUsers();
                closeEvent();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "users";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    return (
        <>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                Edit User
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        error={false}
                        value={name}
                        onChange={handleNameChange}
                        label="Name"
                        size="small"
                        sx={{ marginTop: "30px", minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={false}
                        value={email}
                        onChange={handleEmailChange}
                        label="Email"
                        size="small"
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={false}
                        value={password}
                        onChange={handlePasswordChange}
                        label="Password"
                        type="password"
                        size="small"
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        sx={{ minWidth: "100%" }}
                        size="small"
                    >
                        <InputLabel id="branch-label">Type*</InputLabel>
                        <Select
                            labelId="branch-label"
                            size="small"
                            value={type}
                            label="Type*"
                            onChange={handleTypeChange}
                        >
                            <MenuItem value="User">User</MenuItem>
                            <MenuItem value="Admin">Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <Button variant="contained" onClick={createUser}>
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
