import { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import "../Dashboard.css";
import CountUp from "react-countup";
import GroupIcon from "@mui/icons-material/Group";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Home() {
    const baseURL = process.env.REACT_APP_API_URL;
    const updateUsers = useAppStore((state) => state.updateUsers);
    const updateYourHealers = useAppStore((state) => state.updateYourHealers);
    const updateYourPatients = useAppStore((state) => state.updateYourPatients);
    const updatePatient = useAppStore((state) => state.updatePatient);
    const updateCategories = useAppStore((state) => state.updateCategories);
    const [usercount, setUsercount] = useState(0);
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const useremail = useAppStore((state) => state.useremail);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsers();
        getHealers();
        getPatients();
        getSymptoms();
        countUsers();
        if (utype == "Admin") {
            getPatient();
        } else if (utype == "Healer") {
            getHealerPatients();
        }
    }, []);

    const getPatient = async () => {
        let posturl = baseURL + "patient";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updatePatient(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getHealerPatients = async () => {
        let posturl = baseURL + "healerpatient?que=" + useremail;
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updatePatient(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const countUsers = async () => {
        let posturl = baseURL + "countusers";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log("count: ", res.data.user);
                setUsercount(res.data.user);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getSymptoms = async () => {
        let posturl = baseURL + "symptom";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateCategories(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getHealers = async () => {
        let posturl = baseURL + "healers";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateYourHealers(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getPatients = async () => {
        let posturl = baseURL + "patient";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateYourPatients(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "users";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    return (
        <>
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Stack direction="row" spacing={2}>
                                    <Card
                                        className="gradient"
                                        sx={{ minWidth: 49 + "%", height: 140 }}
                                    >
                                        <div className="iconstylewhite">
                                            <GroupIcon />
                                        </div>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                sx={{ color: "#f0fcfc" }}
                                            >
                                                <CountUp
                                                    delay={0.2}
                                                    end={usercount}
                                                    duration={0.3}
                                                />
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ color: "#ccd1d1" }}
                                            >
                                                Total Users
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    );
}
